import { Digest } from "../../containers/Helpers/digest";
import lsp_energi from "../../assets/img/brand/lsp-energi.png";
import lsp_gppb from "../../assets/img/brand/Logo-LSP-GPPB.png";
import lsp_abi from "../../assets/img/brand/lsp-abi.png";
import lsp_msdm from "../../assets/img/brand/msdm.png";
import lsp_pim from "../../assets/img/brand/logo-pim.png";
import lsp_ypia from "../../assets/img/brand/logo-ypia.png";
import lsp_abiLogin from "../../assets/img/brand/lsp-abi@3x.png";
import lsp_fotografi from '../../assets/img/brand/lspfotografi.png';
import lsp_pm from "../../assets/img/brand/logo-LSPPM.png";
import lsp_hki from "../../assets/img/brand/logo-hki.png";
import lsp_ditekindo from "../../assets/img/brand/lspditekindo.png";
import lsp_den from "../../assets/img/brand/den.png";
import demo from "../../assets/img/brand/nas_landscape_white_font.png";
import login from "../../assets/img/brand/nas_landscape.png";
import logot2k3 from "../../assets/img/brand/logot2k3i.png";
import lsp_area from "../../assets/img/brand/area.png";
import lsp_oshe from "../../assets/img/brand/oshe.png";
import lsp_nki from "../../assets/img/brand/nki.png";
import lsp_k3ibl from "../../assets/img/brand/k3ibl.png";
import lsp_lik from "../../assets/img/brand/lik.png";
import lsp_ei from "../../assets/img/brand/lspei.png";

export const apiLocal = "http://192.168.10.10/api/users/login";

// export const cssDemo = "https://run.mocky.io/v3/f1280be1-8cb3-40f7-8af2-ec0bae46d1df"
export const baseUrl = "https://api.aplikasisertifikasi.com/";
// export const urlLinode = "https://ap-south-1.linodeobjects.com/sertimedia/";
export const urlLinode = "https://storage.googleapis.com/sertimedia";
export const urlLinodeAbi = "https://storage.googleapis.com/abiv3";
export const UrlEmbed = "api.aplikasisertifikasi.com/";
export const urlChatMessage = "https://chat.aplikasisertifikasi.com/v1/";
export const urlLPJK = "https://siki.pu.go.id/siki-api";
export const urlSocketChat = "wss://chat-staging.aplikasisertifikasi.com/ws";
export const cssDemo =
  "https://run.mocky.io/v3/b7e771a1-98aa-4527-8449-17aee9d2d62a";
export const cssAbi =
  "https://run.mocky.io/v3/26cccb83-0e04-4a04-914a-f14207b71c09";
export const dataSourceTAS =
  "http://sertimedia.com/files/TVE9PQ/205780ec92608f2.jpg";

// path
export const path_forgotPass = "/public/users/forgot_password";
export const path_notif = "/me/notifications";
export const path_users = "/users";
export const path_admin = "/admins";
export const path_management = "/managements";
export const path_tuk = "/public/tuks";
export const path_tukAdd = "/tuks";
export const path_adminTUK = "/admintuk";
export const path_HomeTuk = "/tuk";
export const path_pleno = "/plenos";
export const path_requirement_master = "/requirement-master";
export const path_competenceField = "/competence_fields";
export const path_schema = "/schemas";
export const path_schemaViews = "/schemas/views";
export const path_subSchema = "/sub_schemas";
export const path_certificate_schema = "/certification-scheme";
export const path_applicant = "/applicants";
export const path_applicantGeneral = "/users/applicants";
export const path_assessments = "/assessments";
export const path_draftAssessment = "/drafts/assessments";
export const path_assessmentsDashboard = "/dashboards/assessments";
export const path_GET_schedule_activity = "/me/schedules/assessments";
export const path_POST_article = "/articles";
export const path_GET_article = "/public/articles";
export const path_assign_assessors = "/find_assessor_not_assign"; //Used in Schedule assessment
export const path_assign_asesi = "/find_applicant_not_assign"; //Used in Schedule assessment
export const path_assign_admins = "/find_admin_not_assign"; //Used in Schedule assessment
export const path_recordArchive = "/schedules/assessments";
export const path_letters = "/letters";
export const path_alumni = "/alumnis";
export const path_certificate = "/certificates";
export const path_accessors = `/accessors`; //GET detail,PUT,DELETE Accessor
export const path_accessorsGeneral = "/users/accessors"; //GET,POST Accessor
export const path_accessorCompetence = "/accessor/competences";
export const path_accessorsSchedule = "/accessor/schedules"; //Used in schedule accessors
export const path_accessorsSkill = "/Assessors/list-skill";
export const path_masterData = "/portfolios";
export const path_masterData_umum = "/portfolios-umum";
export const path_materi_uji = "/materi-uji";
export const path_reqruitmentCluster = "/requirement-cluster";
export const path_refreshToken = "/me/refresh_token/";
export const path_manageSurat = "/letters";
export const path_unitCompetention = "/unit_competences";
export const path_jointRequest = "/join_requests";
export const path_archive = "/archives";
export const path_persyaratanUmum = "/persyaratan_umum";
export const path_persyaratanUmum_otherAsesi = "/persyaratan_umums";
export const path_requirement = "/requirement";
export const path_reqruitmen_file = "/requirement-file";
export const path_jobs = "/jobs";
export const path_religion = "/religion";
export const path_deletePermanenAssessment = "/deleted/assessments";
export const path_restoreAssessment = "/restore/assessments";

// query fields digunakan untuk GET dari server namun belum tentu di tampilin,
// sedangkan columndef digunankan untuk pendefinisian di datatable
export const query_notif =
  "?datatable=1&fields=is_read,message,data,time_stamp&columndef=is_read,message,data,time_stamp";
export const query_assessments =
  "?datatable=1&fields=assessment_id,title,address,tuk_name,created_date,assessor_id&columndef=assessment_id,title,address,tuk_name,created_date,assessor_id";

// button
export var schedules_accessors =
  '<button type="button" class="btn btn-primary " data-toggle="modal" data-target=".bs-example-modal-sm"><i class="fa fa-trash"></i></button>';
export var upload = '<input type="file" onchange="uploadbutton()">';

//m method
export var method_get = "GET";
export var method_post = "POST";
export var method_put = "PUT";
export var method_delete = "DELETE";

//get Role
export function getRole() {
  const role = localStorage.getItem("role");
  return role;
}

export function getIntegration() {
  const name = localStorage.getItem("integration");
  return name;
}
export function isLJKIntegration() {
  let isLJKIntegration = localStorage.getItem('isLPJKIntegration') ? localStorage.getItem('isLPJKIntegration') : false
  
  return isLJKIntegration && isLJKIntegration!=="false" ? true : false
}
export function getUserId() {
  const json = JSON.parse(localStorage.getItem("userdata"));
  const user = json.user_id;
  return user;
}

export function getUserNik() {
  const json = JSON.parse(localStorage.getItem("userdata"));
  const user = json.nik;
  return user;
}

export function getRefId() {
  const json = JSON.parse(localStorage.getItem("selected_permission"));
  const ref = json.ref_id;
  return ref;
}
export function refId() {
  const data_ref_id = localStorage.getItem("ref_id");
  const ref_id = data_ref_id.replace(/['"]+/g, "");
  return ref_id;
}

export function getAPI(path, method, data) {
  const auth = Digest(path, method);
  const options = {
    method: auth.method,
    headers: {
      Authorization: auth.digest,
      "X-Lsp-Date": auth.date,
      "Content-Type": "multipart/form-data",
    },
    url: baseUrl + path,
    data: data,
  };

  return options;
}

export function getLsp() {
  const json = JSON.parse(localStorage.getItem("lsp"));
  return json;
}

//get Language
export const getLanguage = localStorage.getItem("bahasa");

// format capitalize first letter
export function Capital(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

// fungsi untuk membuat ... pada kalimat yang melebihi batas
String.prototype.trunc =
  String.prototype.trunc ||
  function (n) {
    return this.length > n
      ? this.substr(0, n - 1) +
          "..." +
          this.substr(this.length - 6, this.length)
      : this;
  };

//minimize string
export function minimizeString(value) {
  var str = value;

  return str.trunc(7);
}

// Format Date
export function formatDate(value) {
  const date = new Date(value);
  var month = [];
  month[0] = "January";
  month[1] = "February";
  month[2] = "March";
  month[3] = "April";
  month[4] = "May";
  month[5] = "June";
  month[6] = "July";
  month[7] = "August";
  month[8] = "September";
  month[9] = "October";
  month[10] = "November";
  month[11] = "December";
  var d = month[date.getMonth()];
  var day = date.getDate();
  var year = date.getFullYear();
  return day + " " + d + " " + year;
}

// Format Upper Lower Case
export function formatCapitalize(value) {
  var text = value;
  if (value !== undefined) {
    text = text
      .toLowerCase()
      .split(" ")
      .map((item) => item.charAt(0).toUpperCase() + item.substring(1))
      .join(" ");
  } else {
    text = value;
  }

  return text;
}

//regex clear underscore
export function clearUnderscore(value) {
  var text = value.replace(/_/g, " ");

  return text;
}

// getData
export function getData(path, method, data) {
  const auth = Digest(path, method);
  var link = baseUrl + path;

  const options = {
    method: auth.method,
    headers: {
      Authorization: auth.digest,
      "X-Lsp-Date": auth.date,
      "Content-Type": "multipart/form-data",
    },
    url: link,
    data: data,
  };

  return options;
}

export function permission() {
  var local = localStorage.getItem("user_permissions");
  var arrayPermission = local.split(",");

  return arrayPermission;
}

// export function createPermission(item) {
//   return permission().some((value) => value === item + "_CREATE");
// }

export function listPermission(item) {
  return permission().some((value) => value === item + "_LIST");
}

// export function updatePermission(item) {
//   console.log("itemm",item)
//   return permission().some((value) => value === item + "_UPDATE");
// }

// export function deletePermission(item) {
//   return permission().some((value) => value === item + "_DELETE");
// }

export function downloadFile(path, method) {
  const auth = Digest(path, method);
  const options = {
    method: auth.method,
    headers: {
      Authorization: auth.digest,
      "X-Lsp-Date": auth.date,
    },
    url: baseUrl + path,
    responseType: "blob",
  };

  return options;
}

// Config Logo

export const Brand_LSP = (lsp_name) => {
  var Logo = "";
  var title = "";
  if (lsp_name === "lsp_energi") {
    Logo = lsp_energi;
    title = "LSP ENERGI";
    return { Logo, title };
  } else if (lsp_name === "lsp_gppb") {
    Logo = lsp_gppb;
    title = "LSP GPPB";
  } else if (lsp_name === "demo") {
    Logo = demo;
    title = "NAS Application";
  } else if (lsp_name === "lsp_abi") {
    Logo = lsp_abi;
    title = "LSP ABI";
  } else if (lsp_name === "lsp_abiLogin") {
    Logo = lsp_abiLogin;
    title = "LSP ABI";
  } else if (lsp_name === "lsp_pm") {
    Logo = lsp_pm;
    title = "LSP PM";
  } else if (lsp_name === "ypia") {
    Logo = lsp_ypia;
    title = "LSP YPIA";
  } else if(lsp_name === "login") {
    Logo = login;
    title = "NAS Application";
  } else if(lsp_name === "lsp_pim") {
    Logo = lsp_pim;
    title = "LSP PIM";
  } else if(lsp_name === "lsp_hki") {
    Logo = lsp_hki;
    title = "LSP HKI";
  } else if (lsp_name === "lsp_ditekindo") {
    Logo = lsp_ditekindo;
    title = "LSP DITEKINDO";
  } else if (lsp_name === "fotografi") {
    Logo = lsp_fotografi;
    title = "LSP FOTOGRAFI INDONESIA"
  } else if (lsp_name === "lsp_msdm") {
    Logo = lsp_msdm;
    title = "LSP MSDM";
  } else if (lsp_name === "lsp_t2k3"){
    Logo = logot2k3;
    title = "LSP T2K3I";
  } else if (lsp_name === "lsp_den"){
    Logo = lsp_den;
    title = "LSP Daya Eduvokasi Nusantara";
  } else if (lsp_name === "lsp_area"){
    Logo = lsp_area;
    title = "LSP Area Indonesia";
  } else if (lsp_name === "lsp_oshe"){
    Logo = lsp_oshe;
    title = "LSP OSHE";
  } else if (lsp_name === "lsp_nki"){
    Logo = lsp_nki;
    title = "LSP Norma Ketenagakerjaan Indonesia";
  } else if (lsp_name === "k3ibl") {
    Logo = lsp_k3ibl;
    title = "LSP K3IBL";
  } else if (lsp_name === "lsp_lik") {
    Logo = lsp_lik;
    title = "LSP LIK";
  } else if (lsp_name === "lsp_ei") {
    Logo = lsp_ei;
    title = "LSP Elektronik Indonesia";
  }

  return { Logo, title };
};

// export const Brand_LSP = (lsp_name) => {
//   var Logo = "";
//   var LogoAbi = " ";
//   var LogoEnergi = "";
//   var title = "";
//   if (lsp_name === "pm") {
//     Logo = lsp_pm;
//     title = "LSP PM";
//     return { Logo, title };
//   } else if (lsp_name === "lsp_energi") {
//     LogoEnergi = lsp_energi;
//     title = "LSP ENERGI";
//   } else if (lsp_name === "demo") {
//     Logo = demo;
//     title = "NAS Application";
//   } else if (lsp_name === "login") {
//     Logo = login;
//     title = "NAS Application";
//   } else if (lsp_name === "lsp_abi") {
//     LogoAbi = lsp_abi;
//     title = "LSP ABI";
//   } else if (lsp_name === "lsp_abiLogin") {
//     Logo = lsp_abiLogin;
//     title = "LSP ABI";
//   } else if (lsp_name === "lsp_pm") {
//     Logo = lsp_pm;
//     title = "LSP PM";
//   }

//   return { Logo, LogoAbi, LogoEnergi, title };
// };

// get params query string

export const parseParamsURLquery = (value) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get(value);
};

// delete url query string
export function deleteQueryString(url) {
  return url.split("?")[0];
}

// response interceptors
// export const errorHandler = (error) => {
//   if (isHandlerEnabled(error.config)) {
//     // Handle errors
//   }
//   return Promise.reject({ ...error })
// }

// export const successHandler = (response) => {
//   if (isHandlerEnabled(response.config)) {
//     // Handle responses
//   }
//   return response
// }

export const idLSPABI = "cff88759-27e6-4708-8097-27ed283505b9";
export const idLSPPIM = "86ebf58a-f52d-4b15-9e2c-de28062ef3ae";
export const idDemonstrate = "38d18869-6035-4836-8af0-05bafa26ba12";


export const isLSPABI = () => {
  let id = JSON.parse(localStorage.getItem("ref_id"));
  if(getRole() === "APL"){
    id = localStorage.getItem("lsp_id");
  }
  return id === idLSPABI ? true : false;
}

export const getUserLspId = () => {
  let id = JSON.parse(localStorage.getItem("ref_id"));
  if(getRole() === "APL"){
    id = localStorage.getItem("lsp_id");
  }

  return id;
}

export const listLspDisable = () => {
  const array = [];

  return array;
}

export const checkIfDisable = () => {
  const lspID =  getUserLspId();
  const listDisable = listLspDisable();

  return listDisable.includes(lspID) ? true : false;
}

export const isDemonstrate = () => {
  let id = JSON.parse(localStorage.getItem("ref_id"));
  if(getRole() === "APL"){
    id = localStorage.getItem("lsp_id");
  }
  return id === idDemonstrate ? true : false;
}